<template>
  <div class="OnlineRecharge page">
    <div class="top-message">
      <div class="message-left">
        <img src="~@/assets/head_default.png" />
        <div class="img-right">
          <p class="name">test</p>
          <p>有效日期：2022-10-19 23:59:59</p>
          <a-button style="margin-top: 10px" type="primary" @click="onlineClick"
            >立即充值</a-button
          >
        </div>
      </div>
      <div class="message-right">
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="账户余额(元)">800</a-descriptions-item>
          <a-descriptions-item label="账户余额(元)">200</a-descriptions-item>
          <a-descriptions-item label="账户余额(元)">0</a-descriptions-item>
          <a-descriptions-item label="账户余额(元)">$80.00</a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <a-tabs type="card" v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="扣费记录">
        <div class="search">
          <a-input
            placeholder="请输入"
            style="width: 200px; margin-right: 20px"
          />
          <a-select
            placeholder="请选择"
            style="width: 200px; margin-right: 20px"
          >
            <a-select-option value="1">fgfg</a-select-option>
            <a-select-option value="2">wewe</a-select-option>
            <a-select-option value="3">eerere</a-select-option>
          </a-select>
          <a-select
            placeholder="请选择"
            style="width: 200px; margin-right: 20px"
          >
            <a-select-option value="1">fgfg</a-select-option>
            <a-select-option value="2">wewe</a-select-option>
            <a-select-option value="3">eerere</a-select-option>
          </a-select>
          <a-range-picker style="width: 200px; margin-right: 20px" />
          <a-button type="primary">搜索</a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          rowKey="key"
          :pagination="{
            current: page,
            total: total,
            pageSize: pageSize,
            showSizeChanger: true,
            showTotal: (total) => `总共 ${total} 条`,
            pageSizeOptions: ['20', '50', '100'],
          }"
          @change="pageChange"
        >
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="充值记录">
        <div class="search">
          <a-input
            placeholder="请输入"
            style="width: 200px; margin-right: 20px"
          />
          <a-select
            placeholder="请选择"
            style="width: 200px; margin-right: 20px"
          >
            <a-select-option value="1">fgfg</a-select-option>
            <a-select-option value="2">wewe</a-select-option>
            <a-select-option value="3">eerere</a-select-option>
          </a-select>
          <a-select
            placeholder="请选择"
            style="width: 200px; margin-right: 20px"
          >
            <a-select-option value="1">fgfg</a-select-option>
            <a-select-option value="2">wewe</a-select-option>
            <a-select-option value="3">eerere</a-select-option>
          </a-select>
          <a-range-picker style="width: 200px; margin-right: 20px" />
          <a-button type="primary">搜索</a-button>
        </div>
        <a-table
          :columns="columns2"
          :data-source="data2"
          rowKey="id"
          :pagination="{
            current: page2,
            total: total2,
            pageSize: pageSize2,
            showSizeChanger: true,
            showTotal: (total) => `总共 ${total} 条`,
            pageSizeOptions: ['20', '50', '100'],
          }"
          @change="pageChange"
        >
          <template #finish_time="{ record }">
            {{ formatDate(record.finish_time) }}
          </template>
          <template #create_time="{ record }">
            {{ formatDate(record.create_time) }}
          </template>
          <template #pay_type="{ record }">
            {{ handlePaytype(record.pay_type) }}
          </template>
          <template #state="{ record }">
            {{ handleState(record.state) }}
          </template>
          <template #operation="{ record }">
            <a @click="handleDetail(record.details)">详情</a>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="visible"
      title="充值"
      :maskClosable="false"
      centered
      okText="确定"
      cancelText="取消"
      class="online-recharge"
      @ok="handlePay"
      :width="700"
    >
      <a-form>
        <a-form-item label="话费充值">
          <div class="box">
            <div
              class="box-item"
              :class="{ active: item.id === current }"
              v-for="item in dataSelect"
              :key="item.id"
              @click="handleClick(item.id, 'current')"
            >
              {{ item.description }}
              <img
                v-if="item.id === current"
                src="../../assets/select_money.png"
              />
            </div>
            <!-- <a-input
              style="width: 100px; height: 44px"
              placeholder="其它金额"
            ></a-input> -->
          </div>
        </a-form-item>
        <a-form-item label="ASR充值">
          <div class="box">
            <div
              class="box-item"
              :class="{ active: item.id === current1 }"
              v-for="item in dataSelect2"
              :key="item.id"
              @click="handleClick(item.id, 'current1')"
            >
              {{ item.description }}
              <img
                v-if="item.id === current1"
                src="../../assets/select_money.png"
              />
            </div>
            <!-- <a-input
              style="width: 100px; height: 44px"
              placeholder="其它金额"
            ></a-input> -->
          </div>
        </a-form-item>
        <a-form-item label="短信充值">
          <div class="box">
            <div
              class="box-item"
              :class="{ active: item.id === current2 }"
              v-for="item in dataSelect3"
              :key="item.id"
              @click="handleClick(item.id, 'current2')"
            >
              {{ item.description }}
              <img
                v-if="item.id === current2"
                src="../../assets/select_money.png"
              />
            </div>
            <!-- <a-input
              style="width: 100px; height: 44px"
              placeholder="其它金额"
            ></a-input> -->
          </div>
        </a-form-item>
        <a-form-item label="充值方式">
          <a-radio-group name="radioGroup" v-model:value="readio">
            <a-radio value="1">支付宝</a-radio>
            <!-- <a-radio value="2">微信</a-radio> -->
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visibleCz"
      title="充值结果"
      :maskClosable="false"
      centered
      okText="确定"
      cancelText="取消"
      class="online-recharge"
      @ok="okPay"
      @cancel="okPay"
      :width="350"
    >
      是否充值成功?
    </a-modal>
    <a-modal
      v-model:visible="visibleDetail"
      title="支付套餐详情"
      :maskClosable="false"
      centered
      okText="确定"
      cancelText="取消"
      :width="700"
      @ok="visibleDetail = false"
    >
      <a-descriptions bordered :column="{ xxl: 3 }">
        <a-descriptions-item label="话费">{{
          detailList[0].description
        }}</a-descriptions-item>
        <a-descriptions-item label="ASR">{{
          detailList[1].description
        }}</a-descriptions-item>
        <a-descriptions-item label="短信">{{
          detailList[2].description
        }}</a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { getChargeListIndex, postChongzhi } from "../../service/finace";
import { formatDate } from "../../utils/function";
import { message } from "ant-design-vue";
const data = [
  {
    key: "1",
    name: "胡彦斌",
    age: 32,
    address: "西湖区湖底公园1号",
  },
  {
    key: "2",
    name: "胡彦祖",
    age: 42,
    address: "西湖区湖底公园1号",
  },
];
// const data2 = [
//   {
//     key: "1",
//     name: "胡彦斌",
//     age: 32,
//     address: "西湖区湖底公园1号",
//   },
//   {
//     key: "2",
//     name: "胡彦祖",
//     age: 42,
//     address: "西湖区湖底公园1号",
//   },
//   {
//     key: "3",
//     name: "胡彦祖",
//     age: 42,
//     address: "西湖区湖底公园1号",
//   },
// ];
const columns = [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "年龄",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "住址",
    dataIndex: "address",
    key: "address",
  },
];
const columns2 = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "订单编号",
    dataIndex: "order_num",
  },
  {
    title: "充值金额",
    dataIndex: "money",
  },
  {
    title: "下单时间",
    dataIndex: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  {
    title: "付款时间",
    dataIndex: "finish_time",
    slots: {
      customRender: "finish_time",
    },
  },
  {
    title: "支付方式",
    dataIndex: "pay_type",
    slots: {
      customRender: "pay_type",
    },
  },
  // {
  //   title: "支付流水号",
  //   dataIndex: "payment_no",
  // },
  {
    title: "状态",
    dataIndex: "state",
    slots: {
      customRender: "state",
    },
  },
  {
    title: "操作",
    // key: "operation",
    fixed: "right",
    width: "60px",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "OnlineRecharge",
  setup() {
    const state = reactive({
      page: 1,
      total: undefined,
      pageSize: 20,
      current: 1,
      current1: 1,
      current2: 1,
      visible: false,
      visibleCz: false,
      visibleDetail: false,
      dataSelect: [
        { id: 1, description: "500元", recharge: 0, money: 500 },
        { id: 2, description: "1000元", recharge: 0, money: 1000 },
        { id: 3, description: "2000元", recharge: 0, money: 2000 },
        { id: 4, description: "5000元", recharge: 0, money: 5000 },
        { id: 5, description: "10000元", recharge: 0, money: 10000 },
        { id: 6, description: "50000元", recharge: 0, money: 50000 },
        { id: 7, description: "100000元", recharge: 0, money: 100000 },
      ],
      dataSelect2: [
        {
          id: 1,
          description: "1500元/100万次",
          recharge: 1000000,
          money: 1500,
        },
        {
          id: 2,
          description: "6500元/500万次",
          recharge: 5000000,
          money: 6500,
        },
        {
          id: 3,
          description: "11980元/1000万次",
          recharge: 10000000,
          money: 11980,
        },
        {
          id: 4,
          description: "20000元/2000万次",
          recharge: 20000000,
          money: 20000,
        },
      ],
      dataSelect3: [
        { id: 1, description: "800元/10000条", recharge: 10000, money: 800 },
        { id: 2, description: "2250元/30000条", recharge: 30000, money: 2250 },
        { id: 3, description: "3500元/50000条", recharge: 50000, money: 3500 },
        {
          id: 4,
          description: "6000元/100000条",
          recharge: 1000000,
          money: 6000,
        },
      ],
      readio: "1",
      total2: undefined,
      data2: [],
      page2: undefined,
      pageSize2: 20,
      detailList: [],
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getChargeListIndex({
        access_token: sessionStorage.getItem("token"),
        page: state.page2,
        limit: state.pageSize2,
      });
      state.data2 = res.data.data;
      // state.total2 = total;
      // state.data2 = list;
      if(state.data2.finish_time === 0){
        state.data2.finish_time = null
      }
      console.log(res);
    };
    const pageChange = (e) => {
      console.log(e);
    };
    const handlePay = async () => {
      let params = {
        plan: [],
        money: 0,
      };
      if (state.current) {
        params.plan[0] = state.dataSelect[state.current - 1];
        params.plan[0].type = "phone_charges";
        params.money += params.plan[0].money;
      }
      if (state.current1) {
        params.plan[1] = state.dataSelect2[state.current1 - 1];
        params.plan[1].type = "asr";
        params.money += params.plan[1].money;
      }
      if (state.current2) {
        params.plan[2] = state.dataSelect3[state.current2 - 1];
        params.plan[2].type = "sms";
        params.money += params.plan[2].money;
      }
      if (params.money === 0) {
        message.warn("至少选择一项进行充值");
      }
      console.log(params);
      // params.money =
      //   params.plan[0]?.money + params.plan[1]?.money + params.plan[2]?.money;
      // console.log(params);
      state.visible = false;
      const res = await postChongzhi({
        access_token: sessionStorage.getItem("token"),
        // ...params,
        plan: params.plan,
        money: params.money,
      });
      console.log(res);
      const div = document.createElement("div");
      div.innerHTML = res.data.data;
      document.body.appendChild(div);
      const form = document.querySelector("#alipaysubmit");
      form.target = "_Blank";
      form.submit();
      div.innerHTML = ''
      state.visibleCz = true;
      // console.log(params);
      // switch (current) {
      //   case 1:
      //     params.plan[2] = {
      //       type:
      //     };
      // }
      //   const res = await getPay({
      //     access_token: sessionStorage.getItem("token"),
      //     package_id: state.current,
      //   });
      //   console.log(res);
      //   const div = document.createElement("div");
      //   div.innerHTML = res.data.data;
      //   document.body.appendChild(div);
      //   const form = document.querySelector("#alipaysubmit");
      //   form.target = "_Blank";
      //   form.submit();
      //   state.visibleAdd = false;
      //   state.visible = true;
    };
    const okPay = () => {
      state.visibleCz = false;
      init();
    };
    const onlineClick = () => {
      state.visible = true;
    };
    const handleDetail = (list) => {
      // console.log(list);
      state.detailList = list;
      state.visibleDetail = true;
    };
    const handleClick = (id, play) => {
      if (state[play] === id) {
        state[play] = undefined;
      } else {
        state[play] = id;
      }
    };
    function handlePaytype(str) {
      switch (str) {
        case "weixin":
          return "微信";
        case "alipay":
          return "支付宝";
        case "yeepay":
          return "易宝";
        case "manual":
          return "人工录入";
      }
    }
    function handleState(str) {
      switch (str) {
        case "init":
          return "未支付";
        case "success":
          return "成功";
        case "cancel":
          return "取消";
        case "fail":
          return "失败";
        case "finish":
          return "完成";
      }
    }
    return {
      activeKey: ref("1"),
      ...toRefs(state),
      pageChange,
      data,
      columns,
      handlePay,
      onlineClick,
      handleClick,
      columns2,
      formatDate,
      handlePaytype,
      handleState,
      okPay,
      handleDetail,
    };
  },
};
</script>

<style scoped>
.OnlineRecharge {
  padding: 10px;
  overflow: auto;
}
.top-message {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.message-left {
  display: flex;
}
.message-left img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}
/* .img-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */
.img-right .name {
  font-size: 20px;
  font-weight: 600;
}
.message-right {
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
}
.search {
  margin-bottom: 10px;
}
.online-recharge .ant-form {
  margin: 0 20px;
}
.online-recharge .ant-form-item:nth-last-child(1) {
  margin-bottom: 0;
}
.active {
  border: 1px solid #1890ff !important;
  color: #1890ff;
}
.box {
  display: flex;
  flex-wrap: wrap;
}
.box-item {
  border: 1px solid #ccc;
  width: 160px;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  margin-right: 20px;
}
.box-item img {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>